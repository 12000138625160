@import url('https://fonts.googleapis.com/css2?family=Fraunces:ital,opsz,wght@0,9..144,100..900;1,9..144,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

@layer base {
  * {
    @apply m-0 p-0 box-border;
  }

  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #092d66;
    /* Tailwind's blue-500 */
    border-radius: 16px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #0a328a;
    /* Tailwind's blue-600 */
  }

  /* For Firefox */
  * {
    scrollbar-width:thin;
    scrollbar-color: #153872 #f1f1f1;
  }



  body {
    @apply m-0 font-work-sans-slab antialiased overflow-x-hidden;
  }
}


@layer utilities {

  .font-work-sans-slab {
    font-family: "Work Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
  }
}